import * as React from "react"

// markup
const IndexPage = () => {
  return (
    <p>Veuillez ajouter le nom de l'entreprise après le / dans la barre de recherche.</p>
  )
}

export default IndexPage
